
import { NgModule } from '@angular/core'
import {HttpClientModule} from '@angular/common/http'
import { FormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { RouterModule } from '@angular/router'

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'

import { RouterComponent } from './router.component'
import { AppComponent } from './app.component'
import { VisualComponent } from './components/visual.component'
import { CatalogComponent } from './components/catalog.component'
import { ProdcardComponent } from './components/prodcard.component'
import { OfferComponent } from './components/offer.component'
import { VisualgroupComponent } from './components/visualgroup.component'

import { TooltipDirective }  from './directives/tooltip.directive'

@NgModule({
  declarations: [
    RouterComponent,
    AppComponent,
    VisualComponent,
    CatalogComponent,
    ProdcardComponent,
    OfferComponent,
    VisualgroupComponent,
    TooltipDirective,
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([{ path: '**', component: AppComponent }])
  ],
  providers: [],
  bootstrap: [RouterComponent]
})
export class AppModule { }
