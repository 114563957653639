import { Component, OnInit, Input } from '@angular/core'

import { UIDbService } from '@UI/services/db.service'

@Component({
  selector: 'ui-prodcard',
  templateUrl: './prodcard.component.html',
})
export class ProdcardComponent implements OnInit {
  @Input() product: any
  @Input() catId: number = 0

  constructor(public uiDbService: UIDbService) { }

  ngOnInit(): void {
    // console.log(this.product.oid, this.product.links)
  }

}
