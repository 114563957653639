import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-router',
  templateUrl: './router.component.html'
})
export class RouterComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }
}
