<div class="catalog">
    <!-- <div class="catheader">
            <input type="number" [value]="uiDbService.visuals[uiDbService.selectedVisualId].height" min="100" max="600" step="50"
                (blur)="changeHeight($event)"
            >
            <select [(ngModel)]="uiDbService.visuals[uiDbService.selectedVisualId].diamId"
                (change)="changeDiam($event)"
            >
                <option *ngFor="let diam of uiDbService.diameters" value="{{diam.oid}}">{{ diam.descr }}</option>
            </select>
            <input type="color" [(ngModel)]="uiDbService.visuals[uiDbService.selectedVisualId].color" 
                (change)="changeColor($event)"
            >
        </div> -->
        <div *ngFor="let cat of products;let i=index" class="catgroup">
            <div class="cattitle" (click)="setCat(cat.oid)">
                <fa-icon *ngIf="currCat == cat.oid; else iconright" [icon]="uiDbService.icons.down"></fa-icon>
                <ng-template #iconright>
                    <fa-icon [icon]="uiDbService.icons.right"></fa-icon>
                </ng-template>
                {{ cat.label }}
            </div>
            <ng-container *ngIf='currCat == cat.oid'>
                <ng-container *ngIf='firstCat == cat.oid; else prods' >
                    <div class="category" style="display:block;">
                        <table style="margin-bottom: 20px;">
                            <tr>
                                <td>{{uiDbService.getUiText(6)}}</td>
                                <td>
                                    <input type="text" [(ngModel)]="uiDbService.visuals[uiDbService.selectedVisualId].label" min="1" max="50">  
                                </td>
                            </tr>
                            <tr>
                                <td>{{uiDbService.getUiText(1)}}</td>
                                <td>
                                    <select [(ngModel)]="uiDbService.visuals[uiDbService.selectedVisualId].diamId"
                                        (change)="changeDiam($event)"
                                    >
                                        <option *ngFor="let diam of uiDbService.diameters" value="{{diam.oid}}">{{ diam.descr }}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>{{uiDbService.getUiText(3)}}</td>
                                <td>
                                    <input type="number" [value]="uiDbService.visuals[uiDbService.selectedVisualId].height" min="100" max="600" step="50"
                                        (blur)="changeHeight($event)"
                                    >    
                                </td> 
                            </tr>
                            <tr>
                                <td>{{uiDbService.getUiText(2)}}</td>
                                <td>
                                    <input type="color" [(ngModel)]="uiDbService.visuals[uiDbService.selectedVisualId].color" 
                                        (change)="changeColor($event)"
                                    >    
                                </td> 
                            </tr>
                            <!-- <tr>
                                <td>{{uiDbService.getUiText(5)}}</td>
                                <td>
                                    <input type="checkbox" [(ngModel)]="uiDbService.visuals[uiDbService.selectedVisualId].isMetal" (change)="changeColor($event)">    
                                </td> 
                            </tr> -->
                        </table>
                    </div>
                    <div class="category">
                        <ng-container *ngFor="let prod of products[i].children">
                            <ui-prodcard
                                [product]="prod"
                                [catId]="cat.oid"
                                
                                (click)="changeColType(prod)"
                            ></ui-prodcard>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-template #prods>
                    <div *ngIf="products[i].children.length > 0; else emptyCat" class="category" >
                        <ng-container *ngFor="let prod of products[i].children">
                            <ui-prodcard
                                [product]="prod"
                                [catId]="cat.oid"
                                
                                (click)="uiDbService.addObject(prod, currCat)"
                            ></ui-prodcard>
                        </ng-container>
                        <div *ngIf="products[i].freePosition" class="position">
                            <div>
                                <input type=number [min]="uiDbService.visuals[uiDbService.selectedVisualId].range.min" [max]="uiDbService.visuals[uiDbService.selectedVisualId].range.max" [(ngModel)]="uiDbService.visuals[uiDbService.selectedVisualId].range.h" [attr.unit]="'mm'" step=10
                                    (change)="changeCenterpos($event)"
                                >
                                <input type="range" [min]="uiDbService.visuals[uiDbService.selectedVisualId].range.min" [max]="uiDbService.visuals[uiDbService.selectedVisualId].range.max" [(ngModel)]="uiDbService.visuals[uiDbService.selectedVisualId].range.h" step=1
                                    (change)="changeCenterpos($event)"
                                >
                            </div>
                            <div style="text-align: end;">
                                {{uiDbService.getUiText(4)}}:
                                <input type="checkbox" [(ngModel)]="uiDbService.visuals[uiDbService.selectedVisualId].range.isMirrored" (change)="changeCenterpos($event)">
                            </div>
                            
                        </div>
                    </div>
                    <ng-template #emptyCat>
                        geen producten
                    </ng-template>
                </ng-template>
            </ng-container>
        </div> 
    </div>