import { Component, OnInit, AfterViewInit } from '@angular/core'

import _ from 'lodash'

import { UIDbService } from '@UI/services/db.service'

import { Product } from '@UI/models/products.model'

@Component({
  selector: 'ui-catalog',
  templateUrl: './catalog.component.html',
})
export class CatalogComponent implements OnInit, AfterViewInit {

  public products: any = []
  // public allProducts: any = []
  public currCat: number = -1
  public firstCat: number = 0
  
  constructor(public uiDbService: UIDbService) { }

  ngOnInit(): void {
      this.uiDbService.languageSubject.subscribe(() => {
        this.fetchProducts()
      })
      this.fetchProducts()
      this.firstCat = this.products[0].oid
      this.setCat(this.firstCat) // eerste open
      setTimeout(() => this.changeColType(this.products[0].children[0]), 0)
  }

  ngAfterViewInit(): void {
    // this.changeColType(this.products[0].children[0])

  }

  private fetchProducts = () => {
    this.products = _.filter(this.uiDbService.products, (x: Product) => x.parentId === 0)
    this.filterProds()
  }

  filterProds = () => {
    _.forEach(this.products, (x: Product) => {
      x.children = _.filter(this.uiDbService.products, (y: Product) => (y.diamId == 0 || y.diamId == this.uiDbService.visuals[this.uiDbService.selectedVisualId].diamId) && y.parentId > 0 && this.matchPos(x, y) && this.matchColType(y))
    })
  }

  matchPos = (group: Product, prod: Product): boolean => {
    return  (group.isBase === prod.isBase && prod.isBase === true) || 
            (group.isTop === prod.isTop && prod.isTop === true) || 
            (group.isRing === prod.isRing && prod.isRing === true) || 
            (group.isShaft === prod.isShaft && prod.isShaft === true)
  }

  matchColType = (prod: Product): boolean => {
    if (prod.parentId === 6) return true  //kolommen altijd tonen

    return  (this.uiDbService.visuals[this.uiDbService.selectedVisualId].colType.round === prod.availRound && prod.availRound === true) || 
    (this.uiDbService.visuals[this.uiDbService.selectedVisualId].colType.kartel === prod.availKartel && prod.availKartel === true)
  }

  resetObjects = () => {
    this.uiDbService.redrawSubject.next({ redrawGrid: true, clearObjects: true})
  }

  changeDiam = (e: any) => {
    // console.log(this.uiDbService.visuals[this.uiDbService.selectedVisualId].diamId, e)
    this.uiDbService.visuals[this.uiDbService.selectedVisualId].diamId = +e.srcElement.value
    this.resetObjects()
    this.filterProds()
    this.changeColType(this.products[0].children[0])
  }

  changeColType = (e: any) => {
    // console.log(this.uiDbService.visuals[this.uiDbService.selectedVisualId].colType, e)
    const newType = {round: e.availRound, kartel: e.availKartel}
    this.uiDbService.visuals[this.uiDbService.selectedVisualId].colType = newType

    this.resetObjects()
    this.filterProds()
    this.uiDbService.addObject(e, this.currCat)
  }

  changeColor = (e: any) => {
    this.uiDbService.redrawSubject.next({ redrawGrid: false, clearObjects: false})
  }

  changeHeight = (e: any) => {
    // console.log(e)
    let tmpVal = e.srcElement.value
    if (tmpVal < 100) tmpVal = 100
    if (tmpVal > 600) tmpVal = 600
    
    const inMiddle = (this.uiDbService.visuals[this.uiDbService.selectedVisualId].range.h === this.uiDbService.visuals[this.uiDbService.selectedVisualId].height/2)
    this.uiDbService.visuals[this.uiDbService.selectedVisualId].height = +tmpVal
    if (inMiddle) this.uiDbService.visuals[this.uiDbService.selectedVisualId].range.h = +tmpVal/2
    this.uiDbService.redrawSubject.next({ redrawGrid: true, clearObjects: false, resetView: true})
  } 
  changeCenterpos = (e: any) => {
    this.uiDbService.redrawSubject.next({ redrawGrid: false, clearObjects: false})
    // this.uiDbService.visuals[this.uiDbService.selectedVisualId].range.h = e.srcElement.value
  }

  setCat = (index: number) => {
    this.currCat = (this.currCat == index) ? -1 : index
  }

}
