import { Component, OnInit } from '@angular/core'

import { UIDbService } from '@UI/services/db.service'
import { Visual } from '@UI/models/products.model'

@Component({
  selector: 'ui-visual-group',
  templateUrl: './visualgroup.component.html',
})
export class VisualgroupComponent implements OnInit {

  constructor(public uiDbService: UIDbService) { }

  ngOnInit(): void {
  }

}
