<div class="product HAND" [ngClass]="{ 'selected': uiDbService.visuals[uiDbService.selectedVisualId].column['p' + catId] == product.oid }" >
    <div class="thumb"><img src="{{uiDbService.settings.baseUrl}}/assets/thumbs/{{product.image}}" alt="{{ product.oid }}"></div>
    <div class="thumbtext">{{ product.label }}</div>
    <div class="thumbicons FLEX-ROW">
        <ng-container *ngFor="let link of product.links">
            <a href="{{link.url}}" target="_link">
                <fa-icon *ngIf="link.isLocal; else icondownload" [icon]="uiDbService.icons.link"></fa-icon>
                <ng-template #icondownload>
                    <fa-icon [icon]="uiDbService.icons.download"></fa-icon>
                </ng-template>
            </a>
        </ng-container>
    </div>
</div>
