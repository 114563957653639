<div class="FLEX-ROW offer">
    <div class="FLEX-ROW FLEXWRAP FLEXBETWEEN FULL">
        <!-- <div *ngFor="let visual of uiDbService.visuals; let i=index" class="offerCard">
            <div class="title">{{visual.label}}</div>

            <div class="content FLEX-ROW">
                <div class="FLEX-COLUMN">
                    <div class="item" [attr.data-label]="uiDbService.getUiText(1)">{{ uiDbService.diameters[visual.diamId].descr }}</div>
                    <div class="item" [attr.data-label]="uiDbService.getUiText(3)">{{ visual.height }}</div>
                    <div class="item" [attr.data-label]="uiDbService.getUiText(2)">{{ visual.color }}</div>
                    <div class="item" [attr.data-label]="uiDbService.getUiText(5)">{{ !!visual.isMetal }}</div>
                </div>
                <div class="FLEX-COLUMN">
                    <div *ngFor="let cat of cats" class="item" [attr.data-label]="cat.label">
                        {{ uiDbService.getProdById(visual.column['p'+ cat.oid] || -1)?.label || uiDbService.getUiText(7) }}
                        <div class="attr" *ngIf="cat.freePosition && uiDbService.getProdById(visual.column['p'+ cat.oid] || -1)">
                            {{uiDbService.getUiText(20)}}: {{visual.range.h}}<br/>
                            {{uiDbService.getUiText(4)}}: {{!!visual.range.isMirrored}}
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="formulier FLEX-COLUMN RELATIVE">
            <div [innerHTML]="uiDbService.getUiText(31)"></div>
            <div *ngIf="sendingForm" class="loader" [innerHTML]="modalMsg"></div>
            <div class="FLEX-ROW form">
                <div class="FLEX-COLUMN">
                    <div class="item" [attr.data-label]="uiDbService.getUiText(8)"><input type="text" (blur)="validateForm()" [(ngModel)]="formValidAnswer['naam'].value" [ngClass]="{'required': !formValidAnswer['naam'].valid && firstFormTouch}" /></div>
                    <div class="item" [attr.data-label]="uiDbService.getUiText(9)"><input type="text" (blur)="validateForm()" [(ngModel)]="formValidAnswer['bedrijf'].value" /></div>
                    <div *ngIf="formValidAnswer['bedrijf'].value !== ''" class="item" [attr.data-label]="uiDbService.getUiText(33)"><input type="text" (blur)="validateForm()" [(ngModel)]="formValidAnswer['btwnummer'].value" [ngClass]="{'required': !formValidAnswer['btwnummer'].valid && firstFormTouch}" /></div>
                    <div class="item" [attr.data-label]="uiDbService.getUiText(10)"><input type="text" (blur)="validateForm()" [(ngModel)]="formValidAnswer['straat'].value" [ngClass]="{'required': !formValidAnswer['straat'].valid && firstFormTouch}" /></div>
                    <div class="item" [attr.data-label]="uiDbService.getUiText(11)"><input type="text" (blur)="validateForm()" [(ngModel)]="formValidAnswer['postcode'].value" [ngClass]="{'required': !formValidAnswer['postcode'].valid && firstFormTouch}" /></div>
                    <div class="item" [attr.data-label]="uiDbService.getUiText(12)"><input type="text" (blur)="validateForm()" [(ngModel)]="formValidAnswer['stad'].value" [ngClass]="{'required': !formValidAnswer['stad'].valid && firstFormTouch}" /></div>
                </div>
                <div class="FLEX-COLUMN">
                    <div class="item" [attr.data-label]="uiDbService.getUiText(13)"><input type="text" (blur)="validateForm()" [(ngModel)]="formValidAnswer['telefoon'].value" [ngClass]="{'required': !formValidAnswer['telefoon'].valid && firstFormTouch}" /></div>
                    <div class="item" [attr.data-label]="uiDbService.getUiText(14)"><input type="text" (blur)="validateForm()" [(ngModel)]="formValidAnswer['email'].value" [ngClass]="{'required': !formValidAnswer['email'].valid && firstFormTouch}" /></div>
                    <div class="item" [attr.data-label]="uiDbService.getUiText(15)"><textarea [(ngModel)]="formValidAnswer['opmerkingen'].value"></textarea></div>
                </div>
                <div class="FLEX-COLUMN">
                    <div class="item" [attr.data-label]="uiDbService.getUiText(34)"><input type="text" (blur)="validateForm()" [(ngModel)]="formValidAnswer['kolomLengte'].value" [ngClass]="{'required': !formValidAnswer['kolomLengte'].valid && firstFormTouch}" /></div>
                    <div class="item" [attr.data-label]="uiDbService.getUiText(35)"><input type="number" (blur)="validateForm()" [(ngModel)]="formValidAnswer['kolomAantal'].value" [ngClass]="{'required': !formValidAnswer['kolomAantal'].valid && firstFormTouch}" /></div>

                    <div style="text-align: right;"><button (click)="sendForm()" [disabled]="!formValid || sendingForm">{{uiDbService.getUiText(16)}}</button></div>
                </div>
            </div>
        </div>
    </div>
</div>
