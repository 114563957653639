import { HostListener, Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, Input, ChangeDetectionStrategy } from '@angular/core'

import * as THREE from 'three'
import { Interaction } from 'three.interaction'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'

import _ from 'lodash'
import { Subscription } from 'rxjs'

import { dimensionType, Visual, Product, IRedrawSubject, ColumnObject } from '@UI/models/products.model'

import { UIDbService } from '@UI/services/db.service'

@Component({
  selector: 'ui-visual',
  templateUrl: './visual.component.html',
})
export class VisualComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostListener('window:resize', ['$event'])
  onResize = () => {
    // console.log(this.elRef)
    setTimeout(() => {
      this.canvasWidth  = this.elRef.nativeElement.clientWidth
      this.canvasHeight  = this.elRef.nativeElement.clientHeight

      // console.log(this.canvasWidth, this.canvasHeight)

      // if (event.hasResized) {
        
      //   if (event.width > 0 && event.height > 0) {
      //     // console.log('resized', event)
      //     this.canvasWidth = event.width
      //     this.canvasHeight = event.height

      if (!this.renderer) this.createRenderer()
      if (this.camera) {
        this.camera.aspect = this.canvasWidth/this.canvasHeight
        // this.renderer.setPixelRatio((this.canvasWidth/this.canvasHeight))
        this.camera.updateProjectionMatrix()
      }
      // this.renderer.setSize(this.canvasWidth, this.canvasHeight)

          // this.renderer.setSize(150,150)
      //   } else {
      //     this.destroyCanvas()
      //   }
      // }
    }, 0)
  }

  @ViewChild('canvas', {static: true}) canvasEl: ElementRef
  @ViewChild('tooltip', {static: true}) tooltipEl: ElementRef

  @Input() visualData: Visual
  @Input() visualIndex: number

  // THREE OBJECTS
  private renderer: THREE.WebGLRenderer
  private camera: THREE.PerspectiveCamera  // PerspectiveCameraOrthographicCamera
  private scene: THREE.Scene
  private threeInteraction:Interaction
  private controls: OrbitControls
  private sceneCont: THREE.Group = new THREE.Group()
  private gridCont: THREE.Group = new THREE.Group()
  private dataCont: THREE.Group = new THREE.Group()

  // private font
  // private objectMaterial: THREE.MeshPhysicalMaterial
  private objectMaterial: THREE.MeshPhongMaterial
  private floorMaterial: THREE.MeshPhongMaterial //= new THREE.MeshPhongMaterial( { color: 0xfffff0, depthWrite: true } )
  private wallMaterial: THREE.MeshLambertMaterial //= new THREE.MeshPhongMaterial( { color: 0xfffff0, depthWrite: true } )
  private ceilingMaterial: THREE.MeshLambertMaterial //= new THREE.MeshPhongMaterial( { color: 0xfffff0, depthWrite: true } )

  // CANVAS INIT
  // private canvasRef: number = 1000
  // private canvasRefScale: number = 1

  private canvasWidth: number  = 100
  private canvasHeight: number  = 100
  private animationFrames: number[] = [undefined]

  public tooltipText = ''
  public tooltipPos: dimensionType = { x: 0 , y: 0}
  public showTooltip: boolean = false

  public showLoader: boolean = false

  //VARS
  // private objectColor = '0xff0000'
  public focused: boolean = false
  private needsUpdate: boolean = false
  private objectSubscription: Subscription
  private objOffset: number = 0
  private objScale: number = .1
  private defaultZoom: number = .95
  // private defaultRotation: Euler


  constructor(private elRef: ElementRef, public uiDbService: UIDbService) { }

  ngOnInit(): void {
    const textureLoader: THREE.TextureLoader = new THREE.TextureLoader()
    const cubeTextureLoader: THREE.CubeTextureLoader = new THREE.CubeTextureLoader()

    const envMaps = ( () => {
      const path = `${this.uiDbService.settings.baseUrl}/assets/textures/`
      const format = '.jpg'
      const urls = [
        path + 'px' + format, path + 'nx' + format,
        path + 'py' + format, path + 'ny' + format,
        path + 'pz' + format, path + 'nz' + format
      ]

      const reflectionCube = cubeTextureLoader.load( urls )
      reflectionCube.format = THREE.RGBFormat

      const refractionCube = cubeTextureLoader.load( urls )
      // refractionCube.mapping = THREE.CubeRefractionMapping
      refractionCube.mapping = THREE.CubeReflectionMapping
      refractionCube.format = THREE.RGBFormat

      return {
        none: null,
        reflection: reflectionCube,
        refraction: refractionCube
      }
    } )()

    const floorSize = 5
    const surfSize = 0.008
    const surfSizeY = 0.008
    const surfRotation = .45

    const roughnessMaps = ( () => {
      const floorTexture = textureLoader.load(  `${this.uiDbService.settings.baseUrl}/assets/textures/1K_statuaretto_extra___polished___marble_diffuse.png` );
      floorTexture.encoding = THREE.sRGBEncoding
      floorTexture.wrapT = THREE.MirroredRepeatWrapping 
      floorTexture.wrapS = THREE.MirroredRepeatWrapping 
      floorTexture.repeat.set( floorSize, floorSize )

      const floorTextureBump = textureLoader.load(  `${this.uiDbService.settings.baseUrl}/assets/textures/1K_statuaretto_extra___polished___marble_displacement.png` );
      floorTextureBump.encoding = THREE.sRGBEncoding
      floorTextureBump.wrapT = THREE.MirroredRepeatWrapping 
      floorTextureBump.wrapS = THREE.MirroredRepeatWrapping 
      floorTextureBump.repeat.set( floorSize, floorSize)

      const floorTextureSpec = textureLoader.load(  `${this.uiDbService.settings.baseUrl}/assets/textures/1K_statuaretto_extra___polished___marble_specular.png` );
      floorTextureSpec.encoding = THREE.sRGBEncoding
      floorTextureSpec.wrapT = THREE.MirroredRepeatWrapping 
      floorTextureSpec.wrapS = THREE.MirroredRepeatWrapping 
      floorTextureSpec.repeat.set( floorSize, floorSize)

      const floorTextureNorm = textureLoader.load(  `${this.uiDbService.settings.baseUrl}/assets/textures/1K_statuaretto_extra___polished___marble_normal.png` );
      floorTextureNorm.encoding = THREE.sRGBEncoding
      floorTextureNorm.wrapT = THREE.MirroredRepeatWrapping 
      floorTextureNorm.wrapS = THREE.MirroredRepeatWrapping 
      floorTextureNorm.repeat.set( floorSize, floorSize)

      const floorTextureAo = textureLoader.load(  `${this.uiDbService.settings.baseUrl}/assets/textures/1K_statuaretto_extra___polished___marble_ao.png` );
      floorTextureAo.encoding = THREE.sRGBEncoding
      floorTextureAo.wrapT = THREE.MirroredRepeatWrapping 
      floorTextureAo.wrapS = THREE.MirroredRepeatWrapping 
      floorTextureAo.repeat.set( floorSize, floorSize)

      const surfTextureNorm = textureLoader.load(  `${this.uiDbService.settings.baseUrl}/assets/textures/1K-black_metal_1-normal.jpg` );
      surfTextureNorm.encoding = THREE.sRGBEncoding
      surfTextureNorm.wrapT = THREE.RepeatWrapping 
      surfTextureNorm.wrapS = THREE.RepeatWrapping 
      surfTextureNorm.repeat.set( surfSize, surfSizeY )
      surfTextureNorm.rotation = -surfRotation

      const surfTexture = textureLoader.load(  `${this.uiDbService.settings.baseUrl}/assets/textures/1K-black_metal_1-diffuse2.jpg` );
      surfTexture.encoding = THREE.sRGBEncoding
      surfTexture.wrapT = THREE.RepeatWrapping 
      surfTexture.wrapS = THREE.RepeatWrapping 
      surfTexture.repeat.set( surfSize, surfSizeY )
      surfTexture.rotation = surfRotation
      
      const surfTextureBump = textureLoader.load(  `${this.uiDbService.settings.baseUrl}/assets/textures/1K-black_metal_1-displacement.jpg` );
      surfTextureBump.encoding = THREE.sRGBEncoding
      surfTextureBump.wrapT = THREE.RepeatWrapping 
      surfTextureBump.wrapS = THREE.RepeatWrapping 
      surfTextureBump.repeat.set( surfSize, surfSizeY )
      surfTextureBump.rotation = -surfRotation

      const surfTextureSpec = textureLoader.load(  `${this.uiDbService.settings.baseUrl}/assets/textures/1K-black_metal_1-specular.jpg` );
      surfTextureSpec.encoding = THREE.sRGBEncoding
      surfTextureSpec.wrapT = THREE.RepeatWrapping
      surfTextureSpec.wrapS = THREE.RepeatWrapping
      surfTextureSpec.repeat.set( surfSize, surfSizeY )
      surfTextureSpec.rotation = surfRotation

      const surfTextureAo = textureLoader.load(  `${this.uiDbService.settings.baseUrl}/assets/textures/1K-black_metal_1-ao.jpg` );
      surfTextureAo.encoding = THREE.sRGBEncoding
      surfTextureAo.wrapT = THREE.RepeatWrapping
      surfTextureAo.wrapS = THREE.RepeatWrapping
      surfTextureAo.repeat.set( surfSize, surfSizeY )
      surfTextureAo.rotation = surfRotation

      return {
        none: null,
        surfTextureNorm,
        surfTexture,
        surfTextureBump,
        surfTextureSpec,
        surfTextureAo,
        floorTexture,
        floorTextureBump,
        floorTextureSpec,
        floorTextureNorm,
        floorTextureAo
      }
    } )()

    
    // this.objectMaterial = new THREE.MeshPhongMaterial({
    //   color: +this.uiDbService.hexToHex(this.visualData.color, '0x'),
    //   transparent: false,
    //   opacity: 1,
    //   side: THREE.FrontSide,
    //   flatShading: false,
    //   // shading: THREE.SmoothShading,
    //   // depthTest: false, 
    //   // depthWrite: false,
    //   // metalness: .3,
    //   // roughness: .6,
    //   envMap: envMaps.reflection,
    //   // envMapIntensity: 1,
    //   // roughnessMap: roughnessMaps.surfTexture,
    //   map: roughnessMaps.surfTexture,
    //   specularMap: roughnessMaps.specTexture,
    //   bumpMap: roughnessMaps.surfTexture,
    //   bumpScale: 5,
      
    // }) 

    this.floorMaterial = new THREE.MeshPhongMaterial( { 
      map: roughnessMaps.floorTexture, 
      normalMap: roughnessMaps.floorTextureNorm, 
      bumpMap: roughnessMaps.floorTextureBump,
      bumpScale: 3,
      specularMap: roughnessMaps.floorTextureSpec,
      aoMap: roughnessMaps.floorTextureAo,
      depthWrite: true, 
      reflectivity: 0.1,
      envMap: envMaps.reflection,
    } )

    this.wallMaterial = new THREE.MeshPhongMaterial( { 
      // map: roughnessMaps.floorTexture, 
      color: 0xf8f8ff,
      // bumpMap: roughnessMaps.floorTextureBump,
      // bumpScale: 3,
      // specularMap: roughnessMaps.floorTextureSpec,
      // aoMap: roughnessMaps.floorTextureAo,
      depthWrite: true, 
      // reflectivity: 0.1,
      // envMap: envMaps.reflection,
    } )

    this.ceilingMaterial = new THREE.MeshPhongMaterial( { 
      color: 0xffffff,
      // map: roughnessMaps.floorTexture, 
      // normalMap: roughnessMaps.floorTextureNorm, 
      // bumpMap: roughnessMaps.floorTextureBump,
      // bumpScale: 3,
      // specularMap: roughnessMaps.floorTextureSpec,
      // aoMap: roughnessMaps.floorTextureAo,
      depthWrite: true, 
    } )
  
    this.objectMaterial = new THREE.MeshPhongMaterial({
      color: +this.uiDbService.hexToHex(this.visualData.color, '0x'),
      map: roughnessMaps.surfTexture,

      // normalMap: roughnessMaps.surfTextureNorm,
      bumpMap: roughnessMaps.surfTextureBump,
      bumpScale: 0.85,
      specularMap: roughnessMaps.surfTextureSpec,
      aoMap: roughnessMaps.surfTextureAo,
      transparent: false,
      opacity: 1,
      side: THREE.FrontSide,
      // flatShading: true,
      reflectivity: 0.1,
      envMap: envMaps.reflection,
    }) 

    this.uiDbService.redrawSubject.subscribe((x: IRedrawSubject) => {
      // console.log(x, this.visualData)
      if (this.visualData.selected) {
        // console.log('redraw', this.visualData, x)
        // this.redraw()
        
        this.objectMaterial.color.set(+this.uiDbService.hexToHex(this.visualData.color, '0x'))
        // const metalValue = (this.visualData.isMetal) ? .7 : .3
        // const roughnessValue = (this.visualData.isMetal) ? .2 : .6
        // this.objectMaterial.metalness = metalValue
        // this.objectMaterial.roughness = roughnessValue

        this.objectMaterial.needsUpdate = true

        if (x.isNew) {
          // console.log(this.uiDbService.products)
          _.forEach(this.visualData.column, (x: ColumnObject, key: string) => {
            const tmpCat = key.substring(1)
            const tmpProd = _.find(this.uiDbService.products, (y: Product) => y.oid === x)
            if (tmpProd) {
              this.uiDbService.objectSubject.next({prod: tmpProd, cat: +tmpCat})
            }
          })
        }
 
        if (x.clearObjects) {
          this.clearObjects()
        } else { // kolomhoogte correct plaatsen
          // console.log(this.dataCont)
          const tmpObj: THREE.Object3D = this.dataCont.getObjectByName('pos6')
          if (tmpObj) {
            const multiplier = 1/3000*this.visualData.height*10
            tmpObj.scale.setY(this.objScale * multiplier)
          }
        }

        if (x.redrawGrid) {
          this.clearContainer([this.gridCont])
          this.drawGrid()
          // console.log(this.scene, this.gridCont)
        }

        if (x.resetView) {
          // zoom in on the new column
          this.fitCameraToObject(this.camera, this.dataCont.getObjectByName(`pos6`), this.controls, 1, 0)
          // save new control presets
          this.saveControlState()
          this.resetView()
        }

        this.destroyCanvas(false)
        // this.scene.dispose
      }

      this.setMinMax()
      this.onResize()
      this.reposition([1,2,3,4,5,6])
    })

    this.objectSubscription = this.uiDbService.objectSubject
      .subscribe((x: {prod: Product, cat: number}) => {
        if (this.visualData.selected) {
          //wanneer object, anders alles clearen
          if (x.prod) {
            const basisPos: dimensionType = this.calcObjectPosition(x.cat)

            // console.log(basisPos)
            this.showLoader = true
            this.loadObject(x.prod, x.cat, basisPos)
              
            this.visualData.column[`p${x.cat}`] = x.prod.oid
          } else {
            this.clearObjects()
          }
        }
      })
    // this.objectMaterial = new THREE.MeshBasicMaterial({
    //   color: +this.uiDbService.hexToHex(this.objectColor, '0x'),
    //   transparent: false,
    //   opacity: 1,
    //   side: THREE.FrontSide,
    //   depthTest: false, 
    //   depthWrite: false
    // })   
            // var material = new THREE.MeshPhongMaterial({ 
        //     color: 0xff5533, 
        //     specular: 100, 
        //     shininess: 100 }); 

        // console.log(this.visualData)

    // this.objectMaterial = new THREE.MeshLambertMaterial({
    //   color: +this.uiDbService.hexToHex(this.visualData.color, '0x'),
    //   // transparent: false,
    //   // opacity: 1,
    //   side: THREE.FrontSide,
    //   flatShading: true
    //   // depthTest: false, 
    //   // depthWrite: false
    // })  



    // this.objectMaterial = new THREE.MeshStandardMaterial({
    //   color: +this.uiDbService.hexToHex(this.visualData.color, '0x'),
    //   // transparent: false,
    //   // opacity: 1,
    //   // side: THREE.FrontSide,
    //   // flatShading: true,
    //   // depthTest: false, 
    //   // depthWrite: false,
    //   metalness: 0,
    //   roughness: 0,
    //   envMapIntensity: 1.0
    //   // map: TextureLoader.load(`${this.uiDbService.settings.baseUrl}/assets/textures/alu2.jpg`)
    // }) 


    // TextureLoader.load(`${this.uiDbService.settings.baseUrl}/assets/textures/alu2.jpg`, 
    //   texture => {
    //       console.log('texture', texture)
    //       texture.encoding = THREE.sRGBEncoding
    //       texture.wrapS = THREE.RepeatWrapping
    //       texture.wrapT = THREE.RepeatWrapping
    //       texture.repeat.set(.0005,.0005)

    //       // this.objectMaterial.roughnessMap = texture
    //       // this.objectMaterial.map = texture
    //       // this.objectMaterial.roughnessMap.needsUpdate = true
    //       // this.objectMaterial.needsUpdate = true
    //     }, () => {}, (error) => console.log(error))    






    // var material = new THREE.MeshStandardMaterial( { color: 0x0055ff, flatShading: true } );
    // console.log(this.visualData)
  }

  ngAfterViewInit(): void {
    this.onResize()
  }

  ngOnDestroy(): void {
    if (this.objectSubscription) this.objectSubscription.unsubscribe()

    this.destroyCanvas(true)
    // console.log('destroyed')
  }
  private destroyCanvas = (finalClear: boolean = false) => {
    // this.sceneCont = new THREE.Group()
    
    if (finalClear) {
      if (this.scene) this.scene.dispose
      this.animateStopCanvas(0)
      this.scene = null
      this.camera = null
      this.controls = null

      if (this.renderer) {
        this.canvasEl.nativeElement.removeChild(this.renderer.domElement)
        this.renderer.forceContextLoss()
        // this.renderer.context = null
        this.renderer.domElement = null
        this.renderer = null
      }
    }

  }

  // private redraw = () => {

  // }

  private createRenderer = () => {
    // if (this.canvasEl) {
      // RENDERER
      this.renderer = new THREE.WebGLRenderer({ antialias: true }) //
      // this.renderer.setPixelRatio(this.canvasWidth/this.canvasHeight)
      // console.log(this.canvasWidth, this.canvasHeight)
      this.renderer.setSize(this.canvasWidth, this.canvasHeight, true)
      // this.renderer.outputEncoding = THREE.sRGBEncoding
      this.renderer.shadowMap.enabled = true
      this.renderer.shadowMap.type  = THREE.PCFSoftShadowMap  //BasicShadowMap PCFShadowMap PCFSoftShadowMap;

      this.renderer.toneMapping = THREE.ACESFilmicToneMapping
			this.renderer.outputEncoding = THREE.sRGBEncoding

      this.canvasEl.nativeElement.appendChild(this.renderer.domElement)
      this.setupCanvas()
      this.controls.update()


      // console.log(this.objectMaterial)
      // }
  }

  private setupCanvas = () => {
    // SCENE
    if (!this.scene) {
      this.scene = new THREE.Scene()
      this.scene.background = new THREE.Color(0xe6e6e6)
      // this.scene.background = this.envTexture
      // this.scene.fog = new THREE.Fog( 0xffffff, 200, 1200)


       // The X axis is red. The Y axis is green. The Z axis is blue.
      // const axesHelper = new THREE.AxesHelper(250)
      // this.scene.add( axesHelper )

      // const grid = new THREE.GridHelper( 50, 50, 0xffffff, 0x555555 )
      // grid.rotateOnAxis( new THREE.Vector3( 1, 0, 0 ), 90 * ( Math.PI / 180 ) )
      // this.scene.add(grid)

      // CAMERA
      
      this.camera = new THREE.PerspectiveCamera( 45, this.canvasWidth/this.canvasHeight, 1, 1500 )
      // this.camera = new THREE.OrthographicCamera(-this.canvasWidth/2, this.canvasWidth/2, this.canvasHeight/2, -this.canvasHeight/2, .1, 2000)
      
      // this.camera.position.set( -160, 100, -160 )
      // this.camera.position.set( -this.visualData.height*.85, this.visualData.height*.5, -this.visualData.height*.85)
      this.camera.position.set( -this.visualData.height*1.3, this.visualData.height*.5, 0)
      // this.defaultRotation = _.clone(this.camera.rotation)
      // this.camera.rotateY(Math.PI/2)
      // this.camera.lookAt( 0,80, 0)
      this.camera.zoom = this.defaultZoom
      // console.log('orig cam', this.camera, -this.visualData.height*.85, this.visualData.height*.5, -this.visualData.height*.85)
      // this.camera = new THREE.OrthographicCamera(-this.canvasWidth/2, this.canvasWidth/2, this.canvasHeight/2, -this.canvasHeight/2, .1, 2000)
      // this.camera = new THREE.PerspectiveCamera( 50,  this.canvasWidth/this.canvasHeight, 0.1, 2000 )
      // this.camera.position.x = 1200
      // this.camera.position.y = 380
      // this.camera.position.z = 380
      // this.camera.position.set(50,50,50)
      // this.camera.position.set( 10, 10, 10 )
      // this.camera.lookAt(0,50,0)
      // this.camera.rotation.set(0,-0.5,0)
      // this.camera.zoom = .3

      this.scene.add(this.camera)

      // LIGHTS
      // var hemiLight = new THREE.HemisphereLight( 0x222222, 0xcccccc, 4)
      
      const hemiLight = new THREE.HemisphereLight(0xf8f8ff, 0xaabbff, 2)
      hemiLight.position.set( -500, 50, 0 )
      // hemiLight.position.set( 200, 250, -200 )
      // hemiLight.castShadow = true
      // this.scene.add(hemiLight)

      const ambientLight = new THREE.AmbientLight( 0xf8f8ff, 1.25)
      ambientLight.position.set( -1000, 50, 0 )
      // ambientLight.castShadow = true
      this.scene.add( ambientLight )
      
      // const lights:THREE.Light[]  = []
			// lights[0] = new THREE.PointLight( 0xffffff, 2 )
			// lights[0].position.set(  this.camera.position.x+100, this.camera.position.y+100, this.camera.position.z+100 )

      // lights.forEach((x:THREE.Light) => {
      //   x.castShadow = true
      //   x.shadow.mapSize.width = 2048 // default is 512
      //   x.shadow.mapSize.height = 2048
      //   x.shadow.bias = -0.0001
      // })
			// this.camera.add( ...lights)



      const dirLight = new THREE.DirectionalLight(0xf8f8ff, 1)
      dirLight.target = this.dataCont
      dirLight.castShadow = true
      dirLight.shadow.radius = .5
      dirLight.shadow.mapSize.width = 2048 // default is 512
      dirLight.shadow.mapSize.height = 2048
      dirLight.shadow.bias = 0.0001
      // dirLight.position.set(-this.camera.position.x, this.camera.position.y, -this.camera.position.z)
      dirLight.position.set(-750, 50, -500)
      dirLight.shadow.camera.near = 100 // default
      dirLight.shadow.camera.far = 3000 // default
      dirLight.shadow.camera.top = -1500 // default
      dirLight.shadow.camera.right = 1500 // default
      dirLight.shadow.camera.left = -1500 // default
      dirLight.shadow.camera.bottom = 1500 // default
      this.scene.add(dirLight)
      // this.camera.add(dirLight)

      const SpotLight = new THREE.SpotLight(0xf8f8ff, 1.1)
      SpotLight.target = this.dataCont
      SpotLight.castShadow = false
      SpotLight.rotation.set(0, Math.PI/4, 0)
      // SpotLight.shadow.mapSize.width = 2048 // default is 512
      // SpotLight.shadow.mapSize.height = 2048
      // SpotLight.shadow.bias = 0.0001
      // SpotLight.shadow.camera.near = 100 // default
      // SpotLight.shadow.camera.far = 2000 // default
      // SpotLight.shadow.camera.top = -500 // default
      // SpotLight.shadow.camera.right = 500 // default
      // SpotLight.shadow.camera.left = -500 // default
      // SpotLight.shadow.camera.bottom = 500 // default
      // SpotLight.position.set(this.camera.position.x+500, this.camera.position.y+20, this.camera.position.z-500)
      SpotLight.position.set(this.camera.position.x, this.camera.position.y-150, this.camera.position.z)
      this.camera.add(SpotLight)

      // const lighthelper = new THREE.SpotLightHelper(SpotLight)
      // this.scene.add(lighthelper)

      this.threeInteraction = new Interaction(this.renderer, this.scene, this.camera)


      // const light1 = new THREE.AmbientLight( 0x404040, 0.75 ) // soft white light
      // this.scene.add( light1 )

      // const dirLight = new THREE.DirectionalLight( 0xffffff, 1)
      // dirLight.position.set(this.camera.position.x+100, this.camera.position.y+100, this.camera.position.z+100)
      // dirLight.target = this.dataCont
      // dirLight.castShadow = true
      // const dLight = 200
      // const sLight = dLight * 0.25
      // dirLight.shadow.camera.left = -sLight
      // dirLight.shadow.camera.right = sLight
      // dirLight.shadow.camera.top = sLight
      // dirLight.shadow.camera.bottom = -sLight
      // dirLight.shadow.camera.near = dLight / 30
      // dirLight.shadow.camera.far = dLight
      // dirLight.shadow.mapSize.width = 1024 * 2
      // dirLight.shadow.mapSize.height = 1024 * 2    
      // dirLight.shadow.bias = -0.0001

      // this.camera.add(dirLight)
      // this.sceneCont.add(dirLight)

      this.sceneCont.add(this.gridCont, this.dataCont)
      this.scene.add(this.sceneCont)
      this.drawObjects()


      
    } 

    // CONTROLS
    if (!this.controls)  this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    // this.controls.addEventListener( 'change', this.render )
    // this.controls.target.set( 0, 100, 0 )
    this.saveControlState()

    const interaction = new Interaction(this.renderer, this.scene, this.camera)
    interaction.moveWhenInside = true

    // this.raycaster = new THREE.Raycaster()
		// this.raycaster.params.Points.threshold = 1

    this.animateCanvas()
  }


  private saveControlState = () => {
    this.controls.target.set( 0, this.visualData.height/2, 0 )
    this.controls.panSpeed = 1
    this.controls.enableDamping = true
    this.controls.minZoom = -60
    this.controls.maxZoom = 60
    this.controls.minDistance = 10
    this.controls.maxDistance = 300
    this.controls.minPolarAngle = Math.PI/2
    this.controls.maxPolarAngle = Math.PI/2
    this.controls.maxAzimuthAngle = 2*Math.PI - Math.PI/4
    this.controls.minAzimuthAngle = this.controls.maxAzimuthAngle - Math.PI/2
    // this.controls.minAzimuthAngle = Math.PI/6

    this.controls.enabled = true
    this.controls.saveState()
  }

  private drawObjects = () => {
      this.onShowTooltip(-1)

      this.drawGrid()
      // this.drawContent()

      // console.log(this.scene)
  }

  private drawGrid = () => {
    // Base

    // floor
    const floorsize: dimensionType = { w: 1500, h:1500 }
    const floor = new THREE.Mesh( new THREE.PlaneBufferGeometry( floorsize.w, floorsize.h, 50, 50 ), this.floorMaterial)
    floor.name = 'floor'
    floor.rotation.x = - Math.PI / 2
    floor.receiveShadow = true
    floor.castShadow = false
    
    const ceiling = new THREE.Mesh( new THREE.BoxBufferGeometry(floorsize.w, floorsize.h, 1), this.ceilingMaterial)
    // const ceiling = new THREE.Mesh( new THREE.PlaneBufferGeometry( floorsize.w, floorsize.h, 50, 50), this.ceilingMaterial)
    ceiling.name = 'ceiling'
    ceiling.rotation.x = - Math.PI / 2
    ceiling.rotation.y = Math.PI
    ceiling.position.setY(this.visualData.height*10 *this.objScale)
    ceiling.receiveShadow = true
    ceiling.castShadow = false

    const wall = new THREE.Mesh( new THREE.PlaneBufferGeometry( floorsize.w, floorsize.h ), this.wallMaterial)
    wall.name = 'wall'
    wall.rotation.y = -Math.PI/2
    wall.position.set(100, 0 , 0)
    wall.receiveShadow = true

    const plint = new THREE.Mesh( new THREE.BoxBufferGeometry(floorsize.w, 7, 2, 50, 2, 1), this.wallMaterial)
    plint.name = 'plint'
    plint.rotation.set(wall.rotation.x, wall.rotation.y, wall.rotation.z)
    plint.position.set(wall.position.x, wall.position.y, wall.position.z)
    plint.receiveShadow = true


    // basis cylinder
    // const tmpHeight: number = this.visualData.height*10 *this.objScale
    // const tmpDiamObj = _.find(this.uiDbService.diameters, x => x.oid == this.visualData.diamId)
    // const tmpDiam: number = +tmpDiamObj.value

    // const geometry = new THREE.CylinderGeometry( tmpDiam/2*this.objScale, tmpDiam/2*this.objScale, tmpHeight, 32 )
    // const material = new THREE.MeshBasicMaterial( {color: 0x555555, transparent: true, opacity: .3 } )
    // const cylinder = new THREE.Mesh( geometry, material)
    // cylinder.name = `cylinder-${tmpDiam}`
    // cylinder.position.set(this.objOffset, tmpHeight/2, this.objOffset)

    // const basisPos: dimensionType = {x: 100, y: 0, z: 0, rotationX: 0, rotationY: 0 }
    // const materialMesh = new THREE.MeshBasicMaterial( {color: 0x777777, transparent: true, opacity: .3 } )
    // this.loadObject({object: 'silhouet_1.stl'}, 'silhouet', basisPos, materialMesh, this.gridCont)
    
    this.gridCont.add( floor, ceiling, wall, plint )    //, ceiling, cylinder

    // let obj = new THREE.BoxGeometry( 5, 5, 5)
    
    // var plane = new THREE.Mesh( obj, this.objectMaterial )
    // plane.receiveShadow = true
    // plane.position.set(2.5,2.5,0)
    // this.gridCont.add( plane )    

    // if (faceCamera) this.followCamObjects.push(tmpLabel)

  }

  // private drawContent = () => {

  // }

  // private  loadObject = (path: string, currId: string | number, position: dimensionType, material: any = undefined, container: any = this.dataCont): boolean => {
  private  loadObject = (product: Partial<Product>, currId: string | number, position: dimensionType, material: any = undefined, container: any = this.dataCont): boolean => {
    let id: string
    const path:string = product.object

    if (typeof(currId) === 'number') {
      id = `pos${currId}`
    } else {
      id = currId
    }

    // console.log(path)
    if (path == '') {
      this.clearMesh(id)
      this.setMinMax()
      this.reposition([1, 2, 3, 4, 5, 6])
      this.resetView()
      this.showLoader = false
      return
    }

    if (!material) {material = this.objectMaterial}

    (new STLLoader()).load(`${this.uiDbService.settings.baseUrl}/assets/objects/${path}`, geometry => {
        // console.log(geometry)
          //Als hij al bestaat verwijderen
            this.clearMesh(id, container)
            // geometry.computeVertexNormals()
            // geometry.computeBoundingBox()
            // geometry.computeVertexNormals();
            geometry.center()
            // console.log(tmpBox)
            geometry.rotateX(this.degToRad(product.rotationX))
            geometry.rotateY(this.degToRad(product.rotationY))
            const tmpBox = geometry.boundingBox
            geometry.translate(0, -tmpBox.min.y, 0)

            // calculate vertices to smoothen surface
            const attrib = geometry.getAttribute('position')
            if(!attrib) throw new Error('a given BufferGeometry object must have a position attribute.');

            const positions = attrib.array
            const vertices: THREE.Vector3[] = []
            for (let i = 0; i < positions.length; i += 3) {
                const x = positions[i]
                const y = positions[i + 1]
                const z = positions[i + 2]
                vertices.push(new THREE.Vector3(x, y, z))
            }
            const faces: THREE.Face3[] = []
            for(var i = 0; i < vertices.length; i += 3) {
                faces.push(new THREE.Face3(i, i + 1, i + 2))
            }

            let newGeometry = new THREE.Geometry()
            newGeometry.vertices = vertices
            newGeometry.faces = faces
            newGeometry.mergeVertices()
            
            // proberen smooth en subdivided te maken (v2)
            this.assignUVs(newGeometry)

            // proberen smooth en subdivided te maken
            // newGeometry.faces.map(f => f.vertexNormals = []) // remove vertex normals 
            // newGeometry.faces.map(f => f.normal.set(0,0,0)) // reset face normals
            // newGeometry = this.computeAngleVertexNormals(newGeometry, Math.PI/2)

            // Flat texture faces
            newGeometry.computeFaceNormals()
            
            // smooth surface faces
            // newGeometry.computeVertexNormals()
            
            newGeometry.computeBoundingBox()

            // create object mesh
            const obj = new THREE.Mesh(newGeometry, material)
            obj.receiveShadow = true
            obj.name = id
            obj.userData.objHeight = product.objHeight
            
            if (id ==='silhouet') {
              obj.scale.set(1.8, 1.8, 1.8)
              obj.castShadow = false
            } else {
              let multiplier = 1
              // basis object van 2000 hoog omzetten naar correcte hoogte
              if (position.h) multiplier = 1/3000*position.h*10
              -tmpBox.min.y
              obj.scale.set(this.objScale, this.objScale * multiplier, this.objScale)
              obj.castShadow = true

              //@ts-ignore
              obj.on('pointerover', (e) => {

                this.onShowTooltip(e, product.label)
              })
              //@ts-ignore
              obj.on('pointerout', (e) => {
                this.onShowTooltip()
              })
            }

            obj.rotation.set(position.rotationX, position.rotationY, 0)

            // const posXOffset = (product.rotationX !== 180) ? 0 : obj.geometry.
            obj.position.set(position.x, position.y, position.z)


            // this.camera.lookAt(obj.position)
            // this.camera.zoom = 1
            
            // obj.updateWorldMatrix(true, false)
            
            container.add(obj)
           
            this.setMinMax()
            
            // Rest herpositioneren indien nodig
            let fitOffset = 1
            let correctionByAngle = 0

            switch(currId) {
              case 1:
                // rotate 45° to show more detail
                // obj.rotateY(-Math.PI/4)


                this.reposition([2, 3, 6])
                // fitOffset = 1.1
                correctionByAngle = 0.28
                break
              case 2:
                this.reposition([3])
                correctionByAngle = 0.28
              case 3:
                this.reposition([3])
              case 4:
                this.reposition([3])
                break
              case 5:
                // rotate 45° to show more detail
                // obj.rotateY(-Math.PI/4)

                this.reposition([3, 4, 6])
                break
              case 6: // kolom
                break
            } 
            if (currId !== 6 && id !=='silhouet') this.fitCameraToObject(this.camera, obj, this.controls, fitOffset, correctionByAngle)
           
            // console.log('geladen')
            this.showLoader = false
          }
      )

  }

  private calcObjectPosition = (posId: number): dimensionType => {
    const basisPos: dimensionType = {x: this.objOffset, y: 0, z: this.objOffset, rotationX: 0, rotationY: 0}
    let tmpObj: any
    // console.log(posId)

    switch(posId) {
      case 1:
        basisPos.rotationX = Math.PI
        basisPos.y = this.visualData.height*10 * this.objScale
        break
      case 2:
        basisPos.rotationX = Math.PI
        tmpObj = this.dataCont.getObjectByName('pos1')
        // console.log(1, tmpObj)
        if (tmpObj) {
          const tmpY = (tmpObj.userData.objHeight > 0) ? tmpObj.userData.objHeight : tmpObj.geometry.boundingBox.max.y
          basisPos.y = (this.visualData.height*10 - tmpY)* this.objScale
        } else {
          basisPos.y = this.visualData.height*10 * this.objScale
        }

        break
      case 3:
        tmpObj = this.dataCont.getObjectByName('pos3')

        // console.log(tmpObj)

        if (tmpObj) {
          const objHeight = tmpObj.geometry.boundingBox.max.y

          if (this.visualData.range.h <= this.visualData.range.min + objHeight/2) {
            basisPos.y = this.visualData.range.h
          } else if (this.visualData.range.h >= this.visualData.range.max - objHeight/2) {
            basisPos.rotationX = Math.PI
            basisPos.y = this.visualData.range.h
          } else {
            basisPos.rotationX = Math.PI
            basisPos.y = this.visualData.range.h - objHeight/2
          }
          if (this.visualData.range.isMirrored) basisPos.rotationX += Math.PI
          basisPos.y *= this.objScale
        }
        break
      case 4:
        tmpObj = this.dataCont.getObjectByName('pos5')
        if (tmpObj) {
          const tmpY = (tmpObj.userData.objHeight > 0) ? tmpObj.userData.objHeight : tmpObj.geometry.boundingBox.max.y
          basisPos.y = tmpY * this.objScale
        }
        break
      case 5:
        break
      case 6: // kolom
        // console.log('kolom')
        let tmpHeight = this.visualData.height*10
        // console.log(tmpHeight)
        tmpObj = this.dataCont.getObjectByName('pos5')
        if (tmpObj) {
          const tmpY = (tmpObj.userData.objHeight > 0) ? tmpObj.userData.objHeight : tmpObj.geometry.boundingBox.max.y
          basisPos.y = tmpY * this.objScale
          tmpHeight -= tmpY
          // console.log(tmpHeight, tmpY)
        }
        tmpObj = this.dataCont.getObjectByName('pos1')
        // console.log(1, tmpObj)
        if (tmpObj) {
          const tmpY = (tmpObj.userData.objHeight > 0) ? tmpObj.userData.objHeight : tmpObj.geometry.boundingBox.max.y
          tmpHeight -= tmpY
          // console.log(tmpHeight, tmpY)
        }

        basisPos.h = tmpHeight * this.objScale
        break
    }

    return basisPos
  }

  private clearMesh = (id: string, container: any = this.dataCont) => {
    const tmpObj: any = container.getObjectByName(id)
    if (tmpObj) {
      tmpObj.geometry.dispose()
      container.remove(tmpObj)
    }
  }

  private animateCanvas = () => {
    // this.animationFrames[0] = undefined
    
    this.updateCanvas()
    // if (this.visualData.rotate) this.dataCont.rotateY(this.dataCont.rotation.y + .01)
    if (!this.renderer) return

    this.renderer.render(this.scene, this.camera)
    // console.log(this.animationFrames[0])
    this.animationFrames[0] = requestAnimationFrame(this.animateCanvas)
    // if (!this.animationFrames[0]) { this.animationFrames[0] = requestAnimationFrame(this.animateCanvas) }
  }

  private updateCanvas = () => {
    if ((this.focused || this.needsUpdate) && this.controls) {
      // this.followCamObjects.forEach(x => x.quaternion.copy(this.camera.quaternion))

      this.camera.zoom = this.defaultZoom
      this.camera.updateProjectionMatrix()

      this.controls.update()
      // console.log('update', this.focused)
      this.needsUpdate = false
    }
  }
  private animateStopCanvas = (id: number = 0) => {
    cancelAnimationFrame(this.animationFrames[id])
    // console.log('anim stopped')
  }

  private reposition = (items: number[]) => {
      items.forEach(i => {
        const tmpObj: any = this.dataCont.getObjectByName(`pos${i}`)
        if (!tmpObj) return

        const tmpPos = this.calcObjectPosition(i)
        tmpObj.position.set(tmpPos.x, tmpPos.y, tmpPos.z)
        tmpObj.rotation.set(tmpPos.rotationX, tmpPos.rotationY, 0)
        if (tmpPos.h) {
          const multiplier = 1/3000*tmpPos.h*10
          tmpObj.scale.set(this.objScale, this.objScale * multiplier, this.objScale)
        }
      })
  }

  private setMinMax = () => {
    let min: number = 0
    let max: number = this.visualData.height*10

    const inMiddle = (this.visualData.range.h === this.visualData.range.min + (this.visualData.range.max - this.visualData.range.min)/2)

    let tmpObj: any = this.dataCont.getObjectByName('pos1')
    if (tmpObj) max -= tmpObj.geometry.boundingBox.max.y
    
    tmpObj = this.dataCont.getObjectByName('pos2')
    if (tmpObj) max -= tmpObj.geometry.boundingBox.max.y
    
    tmpObj = this.dataCont.getObjectByName('pos4')
    if (tmpObj) min += tmpObj.geometry.boundingBox.max.y
    
    tmpObj = this.dataCont.getObjectByName('pos5')
    if (tmpObj) min += tmpObj.geometry.boundingBox.max.y
    
    if (inMiddle) {
      this.visualData.range.h = min + (max - min)/2
    } else {
      if (this.visualData.range.h < min) this.visualData.range.h = min
      if (this.visualData.range.h > max) this.visualData.range.h = max
    }
    this.visualData.range.min = min
    this.visualData.range.max = max
  }

  private clearObjects = () => {
    for (let i=1; i<=6; i++) {
      this.loadObject({object: ''}, i, null)
    }
  }

  private clearContainer = (objects: THREE.Group[]) => {
    objects.forEach(obj => {
      for (let i=obj.children.length-1; i >= 0; i--) {
        // console.log(i, object.children[i])
        obj.remove(obj.children[i])
      }
    })
  }

  private fitCameraToObject = (camera: THREE.PerspectiveCamera, object: THREE.Object3D, controls: OrbitControls, fitOffset: number, correctionByAngle: number) => {
    const box = new THREE.Box3()
    box.expandByObject( object )
  
    const size = box.getSize( new THREE.Vector3() )
    const center = box.getCenter( new THREE.Vector3() )

    if (!controls) {
      camera.lookAt(center)
      return
    } 

    // Calc size in Y-dir
    const maxSizeV = size.y
    const fitHeightDistanceV = maxSizeV / ( 2 * Math.atan( Math.PI * camera.fov / 360 ) )
    const fitWidthDistanceV = fitHeightDistanceV / camera.aspect
    const distanceV = fitOffset * Math.min( fitHeightDistanceV, fitWidthDistanceV )
    
    // Calc size in X/Z-dir
    const maxSizeH = Math.max( size.x, size.z )
    const fitHeightDistanceH = maxSizeH / ( 2 * Math.atan( Math.PI * camera.fov / 360 ) )
    const fitWidthDistanceH = fitHeightDistanceH / camera.aspect
    const distanceH = fitOffset * Math.max( fitHeightDistanceH, fitWidthDistanceH )
    
    // use 80% of screen to zoom onto element
    const distance = Math.max(distanceV, distanceH) / 0.8
      
    const direction = controls.target.clone()
      .sub( camera.position )
      .normalize()
      .multiplyScalar( distance )

    // controls.maxDistance = distance * 15

    center.y += correctionByAngle*size.y/2
    controls.target.copy( center )
    
    camera.near = distance / 100
    camera.far = distance * 100
    camera.updateProjectionMatrix()

    camera.position.copy( controls.target ).sub(direction)
    // Adjust to fit capitels completely
    // camera.position.y = center.y + Math.cos(camera.rotation.y/2) * distance
    
    controls.update()

  }

  private assignUVs = (geometry: THREE.Geometry) => {

    geometry.faceVertexUvs[0] = []

    geometry.faces.forEach((face) => {

        const components = ['x', 'y', 'z'].sort((a, b) => Math.abs(face.normal[a]) - Math.abs(face.normal[b]))

        var v1 = geometry.vertices[face.a]
        var v2 = geometry.vertices[face.b]
        var v3 = geometry.vertices[face.c]

        geometry.faceVertexUvs[0].push([
            new THREE.Vector2(v1[components[0]], v1[components[1]]),
            new THREE.Vector2(v2[components[0]], v2[components[1]]),
            new THREE.Vector2(v3[components[0]], v3[components[1]])
        ]);

    })

    geometry.mergeVertices()
    geometry.uvsNeedUpdate = true
    geometry.normalsNeedUpdate = true
}


  // private computeAngleVertexNormals = (geometry: THREE.Geometry, angle: number) => {
  //   const origG = geometry.clone()
  //   geometry.computeFaceNormals()
  
  //   const vertexNormals: THREE.Vector3[][] = new Array(geometry.vertices.length).fill([])
    
  //   console.log('1', geometry.faces.length)

  //   for (let i=0; i < geometry.faces.length; i++) {
  //     const face = geometry.faces[i]
  
  //     vertexNormals[face.a].push(face.normal)
  //     vertexNormals[face.b].push(face.normal)
  //     vertexNormals[face.c].push(face.normal)
  //   }
  
  //   console.log('2', vertexNormals)

  //   for (let i = 0; i < geometry.faces.length; i++) {
  //     const face = geometry.faces[i]
  
  //     face.vertexNormals[0] = this.weightedNormal(vertexNormals[face.a], face.normal, angle)
  //     face.vertexNormals[1] = this.weightedNormal(vertexNormals[face.b], face.normal, angle)
  //     face.vertexNormals[2] = this.weightedNormal(vertexNormals[face.c], face.normal, angle)
  //   }
  
  //   console.log('3', geometry.faces.length)
  //   if (!geometry.faces.length) return origG
  //   geometry.normalsNeedUpdate = true
  //   return geometry
  // }

  // private weightedNormal = ( normals: THREE.Vector3[], vector: THREE.Vector3, angle: number ) => {
  //     const newNormal = new THREE.Vector3()
      
  //     normals.forEach((normal) => {
  //       if (normal.angleTo(vector) >= angle) return
  
  //       newNormal.add(normal)
  //     })
      
  //     return newNormal.normalize()
  // }

  // private fitCameraToObject = (camera, object, controls, zoom ) => {

  //   let boundingBox = new THREE.Box3()

  //   // // get bounding box of object - this will be used to setup controls and camera
  //   boundingBox.setFromObject(object)

  //   const center: Vector3 = new Vector3()
  //   const size: Vector3 = new Vector3()

  //   boundingBox.getCenter(center)
  //   boundingBox.getSize(size)

  //   // get the max side of the bounding box (fits to width OR height as needed )
  //   const maxDim = Math.max( size.x, size.y, size.z)
  //   // const fov = camera.fov * ( Math.PI / 180 )
  //   // let cameraZ = Math.abs( maxDim / 4 * Math.tan( fov * 2 ) )

  //   // cameraZ *= offset * this.objScale // zoom out a little so that objects don't fill the screen

  //   // console.log(camera.position.z, cameraZ)

  //   // camera.position.z = cameraZ

  //   // const minZ = boundingBox.min.z
  //   // const cameraToFarEdge = ( minZ < 0 ) ? -minZ + cameraZ : cameraZ - minZ

  //   // camera.far = cameraToFarEdge * 3
  //   // camera.updateProjectionMatrix()

  //   if (controls) {
  //     const filmHeight = camera.getFilmHeight()
  //     const filmWidth = camera.getFilmWidth()

  //     const fitOffset = 1.2
  //     const fitHeightDistance = maxDim / ( 2 * Math.atan( Math.PI * camera.fov / 360 ) );
  //     const fitWidthDistance = fitHeightDistance / camera.aspect;
  //     const distance = fitOffset * Math.max( fitHeightDistance, fitWidthDistance );

  //     console.log(filmHeight, filmWidth, maxDim, fitHeightDistance, fitWidthDistance, distance)

  //     const zoomCorr =  Math.min(filmHeight, filmWidth) / (maxDim /.8)
      
  //     camera.position.set( -this.visualData.height*.85, this.visualData.height*.5, -this.visualData.height*.85)
       
  //     // camera.zoom = zoom * zoomCorr
  //     // this.controls.zoom0 = 10//.75 * zoomCorr
  //     // this.controls.update()
  //     camera.updateProjectionMatrix()
  //     camera.lookAt(center)
  //     // console.log(camera, controls, camera.rotation.x, camera.rotation.y, camera.rotation.z)
  //     // controls.update()
  //     // set camera to rotate around center of loaded object
  //     // controls.target = center

  //     // prevent camera from zooming out far enough to create far plane cutoff
  //     // controls.maxDistance = cameraToFarEdge * 2

  //     // controls.saveState()

  //   } else {
  //       camera.lookAt(center)
  //   }
  //  }

  // BASE FUNCS
  onFocus = (status: boolean) => {
    this.focused = status
    if (!this.controls) return

    if (this.focused) {
      this.controls.enabled = true
      this.animateCanvas()
    } else {
      this.controls.enabled = false
      this.animateStopCanvas()
    }
  }

  onShowTooltip = (e?, label?: string): void => {
    if (e && e.data) {
      // console.log(e)
      this.tooltipPos = {x: e.data.originalEvent.offsetX+30, y: e.data.originalEvent.offsetY+20}
      this.tooltipText = label
      this.showTooltip = true
    } else {
      this.showTooltip = false
    }
  }

  resetView = (): void => {
    if (!this.controls) return

    this.controls.maxDistance = 400
    this.controls.reset()
    // console.log(this.controls, this.camera)
  }

  private degToRad = (angle: number) => {
    return angle * (Math.PI/180)
  }
}