import { Component, OnInit } from '@angular/core'

import _ from 'lodash'
import { UIDbService } from '@UI/services/db.service'

import { IFormValue, Product, Visual } from '@UI/models/products.model'

@Component({
  selector: 'ui-offer',
  templateUrl: './offer.component.html',
})
export class OfferComponent implements OnInit {
  public cats: Product[]  = []
  public formValid: boolean = false
  public firstFormTouch: boolean = false
  public sendingForm: boolean = false
  public modalMsg: string = ''
  public formValidAnswer: { [key: string]: IFormValue } = {
    naam: {required: true, value: '', valid: false, labelId: 8},
    bedrijf: {required: false, value: '', valid: false, labelId: 9},
    btwnummer: {required: true, value: '', valid: false, labelId: 33},
    straat: {required: true, value: '', valid: false, labelId: 10},
    postcode: {required: true, value: '', valid: false, labelId: 11},
    stad: {required: true, value: '', valid: false, labelId: 12},
    telefoon: {required: true, value: '', valid: false, labelId: 13},
    email: {required: true, value: '', valid: false, labelId: 14},
    opmerkingen: {required: false, value: '', valid: false, labelId: 15},
    kolomLengte: {required: true, value: '', valid: false, labelId: 34},
    kolomAantal: {required: true, value: '1', valid: false, labelId: 35},
  }

  constructor(public uiDbService: UIDbService) { }

  ngOnInit(): void {
    this.cats = _.filter(this.uiDbService.products, (x: Product) => x.parentId === 0)
    // console.log(this.uiDbService.visuals, this.uiDbService.products, this.cats)
  }

  public validateForm(): void {
    this.firstFormTouch = true
    this.formValid = _.reduce(this.formValidAnswer, (p: boolean, v: IFormValue, k: string) => {
      if (p === false) return p
      v.valid = k === 'btwnummer' ? this.formValidAnswer.bedrijf.value === '' || v.value !== '' : (v.value !== '' || !v.required)
      return v.valid
    }, true)

  }

  public sendForm() {
    this.modalMsg = `<img src="${this.uiDbService.settings.baseUrl}/assets/ui/DC_Columns_Bol_logo_Animatie2.gif">`
    this.sendingForm = true

    let contactGegevens: string = _.reduce(this.formValidAnswer, (p: string, v: IFormValue) => {
      p += `<tr><td style="width: 150px;" valign="top">${this.uiDbService.getUiText(v.labelId)}</td><td>${v.value.replace(/\n/g, '<br/>')}</td></tr>`
      return p
    }, '')
    contactGegevens = `<table border=0 cellspacing=0 cellpadding=0>${contactGegevens}</table><br/><hr style="border-top: 1px solid #000;"><br/>`

    let kolomGegevens: string = ''
    _.forEach(this.uiDbService.visuals, (visual: Visual) => {
      let tmpKolom = ''
      
      tmpKolom += `<tr><td style="width: 150px;">${this.uiDbService.getUiText(1)}:</td><td>${this.uiDbService.diameters[visual.diamId].descr}</td></tr>`
      tmpKolom += `<tr><td>${this.uiDbService.getUiText(3)}:</td><td>${visual.height}</td></tr>`
      tmpKolom += `<tr><td>${this.uiDbService.getUiText(2)}:</td><td>${visual.color}</td></tr>`
      tmpKolom += `<tr style="border-bottom:2px solid #000;"><td>${this.uiDbService.getUiText(5)}:</td><td>${!!visual.isMetal}</td></tr>`

      this.cats.forEach(cat => {
        const tmpObj = this.uiDbService.getProdById(visual.column['p'+ cat.oid] || -1)

        if (!tmpObj) {
          tmpKolom += `<tr><td>${cat.label}:</td><td>${this.uiDbService.getUiText(7)}</td></tr>`
        } else {
          tmpKolom += `<tr><td>${cat.label}:</td><td>${tmpObj.label}`
          if (cat.freePosition && tmpObj)  tmpKolom += `<br/>${this.uiDbService.getUiText(20)}: ${visual.range.h}<br/>${this.uiDbService.getUiText(4)}: ${!!visual.range.isMirrored}`
          tmpKolom += `</td></tr>`
        }
      })

      kolomGegevens += `<b>${visual.label}</b><br/><table border=0 cellspacing=0 cellpadding=0>${tmpKolom}</table><br/><br/>`
    })

    _.reduce(this.formValidAnswer, (p: string, v: IFormValue) => {
      p += `<tr><td>${this.uiDbService.getUiText(v.labelId)}</td><td>${v.value}</td></tr>`
      return p
    }, '')
    kolomGegevens = `<table border=0 cellspacing=0 cellpadding=0>${kolomGegevens}</table>`

    let content = `<html><head></head><body>
      <b>Contactgegevens</b><br/>${contactGegevens}<br/><br/><br/>
      <b>Kolommen</b><br/>${kolomGegevens}
      </body></html>`

    

    this.uiDbService.dbRequest('POST', {
      sendmail: {
        from: this.uiDbService.reseller?.mail || this.uiDbService.getUiText(32),
        to: [this.uiDbService.reseller?.mail || this.uiDbService.getUiText(18), this.formValidAnswer.email.value],
        bcc: [this.uiDbService.getUiText(32)],
        subject: this.uiDbService.getUiText(19), 
        content: content
      }
    }).subscribe(x => {
      // console.log('maqil', x)
      if (x == 1) {
        this.modalMsg = this.uiDbService.getUiText(17)
        this.resetForm()
      } else {
        this.modalMsg = this.uiDbService.getUiText(21)
      }
      
      setTimeout(() => this.sendingForm = false, 5000)
    })

  }

  public resetForm() : void {
    _.forEach(this.formValidAnswer, (x: IFormValue) => {
      x.value = ''
      x.valid = false
    })
    this.formValid = false
    this.firstFormTouch = false
  }
}