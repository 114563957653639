<router-outlet></router-outlet>

<div *ngIf="!uiDbService.isLoaded;else content">LOADING
<ul>
  <li *ngFor="let loader of loaders">{{loader}}</li>
</ul>  
</div>

<ng-template #content>
  <div class="header FLEX-ROW FLEXBETWEEN">
    <div><img class="sitelogo" [src]="siteLogo"></div>
    
    <div *ngIf="!uiDbService.reseller" class="nav FLEX-ROW">
      <a href="{{uiDbService.getUiText(29)}}" target="_site">{{uiDbService.getUiText(26)}}</a>
      <a href="{{uiDbService.getUiText(30)}}" target="_site">{{uiDbService.getUiText(27)}}</a>
    </div>
    
    <div class="FLEX-ROW" >
      <button (click)="uiDbService.addVariante()">
        <fa-icon [icon]="uiDbService.icons.add"></fa-icon> {{uiDbService.getUiText(25)}}
      </button>
      <button (click)="uiDbService.toggleContact()">
        <fa-icon [icon]="uiDbService.icons.mail"></fa-icon> {{uiDbService.getUiText(28)}}
      </button>
    </div>

    <div class="FLEX-ROW">
      <img *ngFor="let lang of uiDbService.languages" src="{{lang.icon}}"  class="HAND" (click)="setLang(lang.short)" [attr.alt]="lang.alt">
    </div>
  </div>

  <div class="content" role="main">
    <div class="maincontent">
      <div class="maincolumns">
        <ui-visual-group></ui-visual-group>
        <ui-catalog></ui-catalog>
      </div>
    </div>

    <div *ngIf="uiDbService.store.showContact" class="modal">
      <div class="closeBtn" (click)="uiDbService.toggleContact(false)">
        <fa-icon [icon]="uiDbService.icons.close"></fa-icon>
      </div>
      <ui-offer></ui-offer>
    </div>
    <!-- <footer>Nise Solutions</footer> -->
  </div>
</ng-template>