import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { UIDbService } from '@UI/services/db.service'
import _ from 'lodash'

import { ILanguage, IProductLink, IReseller, IUiText, Product } from '@UI/models/products.model'
import { forkJoin } from 'rxjs'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'DCcolumns'

  public loaders = ['languages', 'products', 'diameters', 'uiText', 'reseller']
  public siteLogo = 'https://www.dc-colonnes.be/site/images/logo-dc-columns.png'

  constructor(public uiDbService: UIDbService, private router: Router) { }

  ngOnInit(): void {
    const uri = location.pathname.split('/').filter((x) => x !== '')
    const uriParams = this.processUri(uri)
    this.uiDbService.currLang = uriParams.lang
    this.uiDbService.resellerId = uriParams.resellerId
    

    // Get reseller if hostname is not default or resellerid has been prodivded
    const hostname = location.hostname
    const basePath = [...uri]
    _.remove(basePath, (x: string) => [this.uiDbService.resellerId, this.uiDbService.currLang].includes(x))
    this.uiDbService.settings.baseUrl = basePath.length ? `${location.origin}/${basePath.join('/')}` : location.origin

    if (!this.uiDbService.settings.rootHost.includes(hostname) || this.uiDbService.resellerId) {
      this.uiDbService.dbRequest('POST', {qry:`SELECT * FROM resellers WHERE id='${this.uiDbService.resellerId}' OR host='${location.origin}'`}).subscribe((x: IReseller[]) => {
        // console.log('reseller', x, `SELECT * FROM resellers WHERE id='${this.uiDbService.resellerId}' OR host='${hostname}'`)
        
        if (!x.length) return
        this.uiDbService.reseller = x[0]
        this.siteLogo = `/assets/resellers/${this.uiDbService.reseller.id}.png`
        this.setLoaded('reseller')

        // products
        this.setProducts()
      })
    } else {
      this.setLoaded('reseller')
      
      // products
      this.setProducts()
    }

    // languages
    this.uiDbService.dbRequest('POST', {qry:`SELECT * FROM languages WHERE active=1 ORDER BY sort`}).subscribe((x: ILanguage[]) => {
      if (!x.length) return

      x.forEach((lang) => {
        lang.icon = `https://www.dc-colonnes.be/site/media/mod_languages/images/${lang.icon}.gif`
      })
      this.uiDbService.languages = x
      this.setLoaded('languages')
    })

    // translations
    this.setUiText()


    this.uiDbService.dbRequest('POST', {qry:`SELECT * FROM prod_diameters WHERE active=1`}).subscribe(x => {
      this.uiDbService.diameters = _.orderBy(x, ['order'], ['asc'])
      this.setLoaded('diameters')
    })
  }

  private setLoaded = (id: string) => {
    _.remove(this.loaders, x => x === id)
    this.uiDbService.isLoaded = (this.loaders.length === 0)
  }

  private setUiText = () => {
    this.uiDbService.dbRequest('POST', {qry:`SELECT la.*, lt.text FROM lang la LEFT JOIN lang_txt lt ON la.oid=lt.oid AND lt.lang='${this.uiDbService.currLang}'`}).subscribe((x: IUiText[]) => {
      x.forEach((text) => {
        text.text = text.text || text.descr
      })
      this.uiDbService.UiText = _.mapValues(_.keyBy(_.orderBy(x, ['oid'], ['asc']), 'oid'), 'text')
      // console.log( this.uiDbService.UiText)
      this.setLoaded('uiText')
    })
  }

  private setProducts = () => {
    const extraLinkFilter = this.uiDbService.reseller ? ' AND isReseller=1' : ' AND isLocal=1'

    forkJoin([
      this.uiDbService.dbRequest('POST', {qry:`SELECT P.*, PL.text AS label FROM prod P LEFT JOIN prod_lang PL ON P.oid=PL.id AND PL.lang='${this.uiDbService.currLang}' WHERE active=1 ORDER BY sort`}),
      this.uiDbService.dbRequest('POST', {qry:`SELECT * FROM prod_links WHERE lang='${this.uiDbService.currLang}' ${extraLinkFilter}`}),
    ]).subscribe((data: [Product[], IProductLink[]]) => {
      const [products, productLinks] = data

      productLinks.forEach((link) => {
        link.prodId = +link.prodId
        link.isLocal = this.uiDbService.strToBool(link.isLocal)
        link.isReseller = this.uiDbService.strToBool(link.isReseller)
      })

      this.uiDbService.products = products
      _.forEach(this.uiDbService.products, (x: Product) => {
        x.oid = +x.oid
        x.label = x.label || x.descr
        x.parentId = +x.parentId
        x.order = +x.order
        x.rotationX = +x.rotationX
        x.rotationY = +x.rotationY
        x.diamId = +x.diamId
        x.isBase = this.uiDbService.strToBool(x.isBase)
        x.isTop = this.uiDbService.strToBool(x.isTop)
        x.isRing = this.uiDbService.strToBool(x.isRing)
        x.isShaft = this.uiDbService.strToBool(x.isShaft)
        x.availRound = this.uiDbService.strToBool(x.availRound),
        x.availKartel = this.uiDbService.strToBool(x.availKartel),
        x.active = this.uiDbService.strToBool(x.active)
        x.freePosition = this.uiDbService.strToBool(x.freePosition)
        x.objHeight = +x.objHeight
        x.focusHeight = +x.focusHeight

        x.links = productLinks.filter((link) => link.prodId === x.oid)
      })

      this.setLoaded('products')
      this.uiDbService.languageSubject.next()
     })
  }

  private processUri = (uri: string[]): { lang: string; resellerId: string } => {
    let lang: string = 'nl'
    let resellerId: string

    if (!uri.length) return { lang, resellerId }

    lang = this.stripLangFromString(uri[uri.length-1])

    // if valid uuid
    if (uri[uri.length-2] && uri[uri.length-2].match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$')) {
      resellerId = uri[uri.length-2]
    }
    
    return { lang, resellerId }
  }

  private stripLangFromString = (str: string): string => {
    if (['nl', 'fr', 'en', 'de', 'cz'].includes(str)) return str
    return 'nl'
  }

  public setLang = (lang: string) => {
    this.uiDbService.currLang = lang
    this.setUiText()
    this.setProducts()
    console.log('lang', this.uiDbService.currLang)
  }

}
