import { Injectable, Injector, ErrorHandler, EventEmitter } from '@angular/core'
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http'

import { Observable, of, throwError } from 'rxjs'
import { catchError, take, retry, first  } from 'rxjs/operators'

import _ from 'lodash'

import { faPlus, faMinus, faAngleDown, faAngleRight, faSync, faQuestionCircle, faDownload, faLink, faAt, faTimes } from '@fortawesome/free-solid-svg-icons'

import { Visual, Product, IReseller, ILanguage } from '@UI/models/products.model'

@Injectable({providedIn: 'root'})
export class UIDbService {
  
  
  public settings: any = {
    rootHost: ['localhost', 'configurator.dc-colonnes.be', 'configurateur.dc-colonnes.fr', 'configurator.dc-columns-pillars.com'],
    rootUrl: 'https://configurator.dc-colonnes.be',
    baseHost: location.origin,
    baseUrl: '', //(location.hostname === 'localhost') ? '' : '/configurator-2020'
    restUrl: 'https://configurator.dc-colonnes.be/api.php'
  }

  public jsonHeaders: HttpHeaders = new HttpHeaders()

  public objectSubject: EventEmitter<{prod: Product, cat: number}> = new EventEmitter<any>()
  public redrawSubject: EventEmitter<any> = new EventEmitter<any>()
  public languageSubject: EventEmitter<void> = new EventEmitter<void>()


  public visuals: Visual[] = []
  public selectedVisualId: number = -1
  public icons = {
    add: faPlus, 
    del: faMinus,
    down: faAngleDown,
    right: faAngleRight,
    reset: faSync,
    help: faQuestionCircle,
    download: faDownload,
    link: faLink,
    mail: faAt,
    close: faTimes,
    orbit: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="mdi-rotate-orbit" width="24" height="24" viewBox="0 0 24 24"><path d="M8,14.25L4.75,11H7C7.25,5.39 9.39,1 12,1C14,1 15.77,3.64 16.55,7.45C20.36,8.23 23,10 23,12C23,13.83 20.83,15.43 17.6,16.3L17.89,14.27C19.8,13.72 21,12.91 21,12C21,10.94 19.35,10 16.87,9.5C16.95,10.29 17,11.13 17,12C17,18.08 14.76,23 12,23C10.17,23 8.57,20.83 7.7,17.6L9.73,17.89C10.28,19.8 11.09,21 12,21C13.66,21 15,16.97 15,12C15,11 14.95,10.05 14.85,9.15C13.95,9.05 13,9 12,9L10.14,9.06L10.43,7.05L12,7C12.87,7 13.71,7.05 14.5,7.13C14,4.65 13.06,3 12,3C10.46,3 9.18,6.5 9,11H11.25L8,14.25M14.25,16L11,19.25V17C5.39,16.75 1,14.61 1,12C1,10.17 3.17,8.57 6.4,7.7L6.11,9.73C4.2,10.28 3,11.09 3,12C3,13.54 6.5,14.82 11,15V12.75L14.25,16Z" /></svg>'
  }
  public products: Product[] = []
  public diameters: any = []
  public isLoaded: boolean = false
  public currLang: string = 'nl'
  public resellerId: string
  public reseller: IReseller
  public languages: ILanguage[]

  public store = { showContact: false }

  public UiText: any[] = []

  constructor(private http: HttpClient, private injector: Injector) {
    const tmpVisual: Visual = { label: 'column', order: 0, selected: false, diamId: 1, colType: {round: true, kartel: false}, height: 200, color: '#ababab', column: {}, range: { min: 0, max: 2000, h: 1000 } }
    this.visuals.push(tmpVisual)
    this.setActiveColumn(0)
    
    this.jsonHeaders = this.jsonHeaders.set('Access-Control-Allow-Credentials', 'true')
    this.jsonHeaders = this.jsonHeaders.set('content-type', 'application/json')
    this.jsonHeaders = this.jsonHeaders.set('Access-Control-Allow-Origin', '*')
    this.jsonHeaders = this.jsonHeaders.set( 'Accept', 'application/json')
   }

   setActiveColumn = (index: number) => {
    if (this.selectedVisualId === index) {
      this.visuals[this.selectedVisualId].rotate = !this.visuals[this.selectedVisualId].rotate
    } else {
      if (this.visuals[this.selectedVisualId]) this.visuals[this.selectedVisualId].selected = false
      this.visuals[index].selected = true
      this.selectedVisualId = index
    }
  }
  addVariante = () => {
    const order = this.visuals.length
    this.visuals.push({ ..._.cloneDeep(this.visuals[this.selectedVisualId]), label: `column ${order}`, order: order, selected: true })
    this.setActiveColumn(order)
    // console.log(...this.visuals)
    setTimeout(() => this.redrawSubject.next({ redrawGrid: false, clearObjects: false, isNew: true}), 0)
  }

  delVariante = (index: number) => {
    // console.log(index)
    const tmpVisual = this.visuals[index]
    const wasSelected = tmpVisual.selected

    // delete obj
    this.visuals.splice(index, 1)

    if (wasSelected) this.setActiveColumn(0)
    this.redrawSubject.next({ redrawGrid: false, clearObjects: false})
  }

  addObject = (prod: Product, currCat: number) => {
    this.objectSubject.next({prod: prod, cat: currCat})
  }
  // redraw = (clearContent: boolean = true) => {
  //   this.redrawSubject.next(clearContent)
  // }

  dbRequest = (command: string, object: any): Observable<any> => {
    // console.log(table, object)
    
    if (!object) return of([])

    const qryHeaders: HttpHeaders = this.jsonHeaders
    
    return this.http
      .request<any>(command, this.settings.restUrl, {headers: qryHeaders, body: object})
      .pipe(
        // retry(3),
        take(1),
        // tap(response => {
        //   if (!response.success) {
        //       // Wanneer geen token, default user inloggen
        //       // this.uiDesktopService.login()
        //       console.error('DBRequest: ' + response.message)
        //       // fout loggen

        //       return of(response.message)
        //   } else {
        //       // loggen indien geen GET (insert, update, patch, delete, ...)
        //   }
        // }),
        // map(response => response.data),
        )
  }

  getUiText = (id: number): string => {
    return this.UiText[id]
  }

  hexToHex = (color: any, prefix: string = '#'): any => { 
    try {
        color = prefix + color.toString().slice(-6)
        return color
    } catch(e) {
        console.error(e, color)
        return color
    }
  }

  strToBool = (value: any): boolean => {
    if (value === null) { return false }

    if (value && typeof(value) !== 'boolean') {
        try {
            switch (value.toString().toLowerCase().trim()) {
                case 'true': case 'yes': case '1': return true
                case 'false': case 'no': case '0': case null: return false
                default: return Boolean(value)
            }
        } catch (e) {
            console.error(`Value ${value} to boolean failed.`)
        }
    }
    return value
  }  

  getProdById(id: number): Product {
    return _.find(this.products, (x: Product) => x.oid === id)
  }

  public toggleContact = (val?: boolean) => {
    if (typeof val === 'boolean') {
      console.log('val')
      this.store.showContact = val
      return
    }

    this.store.showContact = !this.store.showContact

  }

}

// HTTP INTERCEPTOR
@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private injector: Injector) { }
   
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
      return next.handle(req)
        .pipe(
          retry(1),
          catchError((err: HttpErrorResponse) => {
            let errorMessage = '';
            if (err.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Error: ${err.error.message}`
            } else {
              // server-side error
              errorMessage = `Error Code: ${err.status}\nMessage: ${err.message}`
            }
            console.info(errorMessage)
            // window.alert(errorMessage)
            // rollbar.error(error)
            return throwError(errorMessage)
          })
        )
    }
}

// ERROR HANDLER
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(err) {
    console.error(err)
  }
}